import React, { useEffect } from 'react';
import Button from '../UiComponents/Button';
import InputField from '../UiComponents/InputField';
import theme from '../../UI/theme';
import { AnimationOverlay, ConnectionConfirmatoinContainer, SetupStatusContainer, SetupStepContainer, SkipButton, SkipButtonContainer, StepManagerContainer } from './SetupStepContainers';
import IconLogo from '../UiComponents/IconLogo';

const CONNECTION_STATES = {
    DISCONNECTED: 'DISCONNECTED',
    CONNECTING: 'CONNECTING',
    CONNECTED: 'CONNECTED',
};

const WifiSetup = ({ hubBluetoothConnection, setSetupStep, SETUP_STEP, wifi, setWifi, internetEnsured}) => {

    const [ssid, setSsid] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [connectionState, setConnectionState] = React.useState(null);
    const [missingSsid, setMissingSsid] = React.useState(false);
    const [showPass, setShowPass] = React.useState(false);
    const [connectionAttemptFailed, setConnectionAttemptFailed] = React.useState(false);
    const connectionRef = React.useRef(connectionState);
    connectionRef.current = connectionState;

    useEffect(() => {
        syncWifiInfo('init');
        hubBluetoothConnection.subscribeWifiData(subscriptionCallback);
    }, []);

    useEffect(() => () => {
        hubBluetoothConnection.unsubscribeWifiData(subscriptionCallback);
    }, []);

    function subscriptionCallback(event) {
            const value = new TextDecoder().decode(event.target.value);
            var parsedData = hubBluetoothConnection.parse(value);
            syncWifiInfo();
    }

    async function syncWifiInfo(data = null) {
        var wifiInfo = await getWifiInfo();
        if (wifiInfo.ssid) {
            setSsid(wifiInfo.ssid);
            setWifi({ ssid: wifiInfo.ssid, ip: wifiInfo.ip, internet: wifiInfo.internet });
            if (wifiInfo.connected) setConnectionState(CONNECTION_STATES.CONNECTED);
            else setConnectionState(CONNECTION_STATES.DISCONNECTED);
        } else if (data !== 'init') {
            setConnectionState(CONNECTION_STATES.DISCONNECTED);
            setConnectionAttemptFailed(true);
        }
    };

    async function getWifiInfo() {
        const wifiInfo = await hubBluetoothConnection.getWifiData();
        if (wifiInfo) {
            return wifiInfo;
        } else return false;
    }

    async function writeWifiInfo() {
        setConnectionAttemptFailed(false);
        if (!ssid?.length) {
            setMissingSsid(true);
            return;
        } else setMissingSsid(false);
        setConnectionState(CONNECTION_STATES.CONNECTING);
        await hubBluetoothConnection.setWifiData({ssid: ssid, password: password});
        setTimeout(() => {
            if (connectionRef.current === CONNECTION_STATES.CONNECTING) syncWifiInfo();
        }, 60000);
    }

    function next() {
        if (!internetEnsured) return;
        else setSetupStep(SETUP_STEP.INSTALLER);
    }

    function back() {
        setSetupStep(SETUP_STEP.ETHERNET);
    }

    function skip() {
        
    }

    return (
        <SetupStepContainer>
            <AnimationOverlay/>
            <h2>Wifi</h2>
            {((!wifi.internet || connectionState === null ) && (connectionState !== CONNECTION_STATES.CONNECTING)) &&<>
                <p style={{width: '100%', maxWidth: '300px'}}>
                    Wifi Name
                    <InputField
                        value={ssid}
                        onChange={(e) => {setSsid(e.target.value)}}
                        onBlur={(e) => {setSsid(e.target.value)}}
                        placeholder='ssid'
                        onClick={() => setConnectionAttemptFailed(null)}
                    />
                </p>
                <p style={{width: '100%', maxWidth: '300px'}}>
                    Password <span style={{fontSize: '14px', float: 'right', color: theme.colors.greenEnergy, cursor: 'pointer'}} onClick={() => setShowPass(!showPass)}>{showPass? 'hide': 'show'}</span>
                    <InputField
                        value={password}
                        onChange={(e) => {setPassword(e.target.value)}}
                        onBlur={(e) => {setPassword(e.target.value)}}
                        placeholder='password'
                        type={showPass? 'text' : 'password'}
                    />
                </p>
                <Button disabled={(connectionState===CONNECTION_STATES.CONNECTING)}primary small onClick={(event) => writeWifiInfo()}>Connect</Button>
                {missingSsid? <p style={{color: theme.colors.raspberryRed}}>Please enter a Wifi Name</p> : null}
            </>}

            
                {(connectionState === CONNECTION_STATES.CONNECTED || connectionState === CONNECTION_STATES.DISCONNECTED && connectionAttemptFailed===false )&&
                    <SetupStatusContainer style={{margin: '70px'}}>
                        <h4 style={{margin: '0px'}}>Connected to:</h4>
                        {wifi.ip ? <p>{wifi.ssid}</p> : <p style={{color: theme.colors.raspberryRed}}>Not Found</p>}
                        <ConnectionConfirmatoinContainer>
                            <p>Wifi Ip:</p>
                            {wifi.ip ? <p>{wifi.ip}</p> : <p style={{color: theme.colors.raspberryRed}}>Undefined</p>}
                            <p >Local net:</p>
                            {wifi.ip ? <p style={{color: theme.colors.greenEnergy}}>{' Connected'}</p> 
                            :   <p style={{color: theme.colors.raspberryRed}}>{' Not Connected'}</p> }
                            <p>Internet:</p>
                            {wifi.internet ? <p style={{color: theme.colors.greenEnergy}}>{' Connected'}</p> 
                            :   <p style={{color: theme.colors.raspberryRed}}>{' Not Connected'}</p> }
                        </ConnectionConfirmatoinContainer>
                    </SetupStatusContainer>
                }

            <SetupStepContainer style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: '40px'}}>
                {(wifi.internet && connectionState !== null && connectionState !== CONNECTION_STATES.CONNECTING) &&
                    <>
                        <Button disabled={false} primary onClick={next}>Next Step</Button>
                        <Button disabled={false} tertiary small onClick={() => setConnectionState(null)}>Try another wifi</Button>
                    </>
                }

                {connectionAttemptFailed===true? <p style={{margin: '0px', color: theme.colors.raspberryRed}}>Could not connect to: {ssid}</p> : null}

                {connectionState === CONNECTION_STATES.CONNECTING?<SetupStatusContainer>
                    <p>Connecting, should only take a minute...</p>
                    <IconLogo preloader/>
                    
                </SetupStatusContainer>: null}
            </SetupStepContainer>

            <StepManagerContainer>
                <Button disabled={false} tertiary small onClick={back}>Back</Button>
                <Button disabled={!internetEnsured} tertiary small onClick={next}>Skip</Button>
            </StepManagerContainer>
        </SetupStepContainer>
    );
};

export default WifiSetup;