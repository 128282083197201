import { hasZigbeeNetwork } from "../modules/hub/hubUtils";
import { singleSpeakerSystems } from "../modules/speaker/speakerUtils";


const tabNotificationCheck = (tabKey, objects) => {
    switch (tabKey) {
        case 'HUB':
            return checkHubNotification(objects.hubs);
        case 'PROCESSOR':
            return checkProcessorNotification(objects.processors);
        case 'SOURCE':
            return checkSourceNotification(objects.sources, objects.sourceSelectors);
        case 'SOURCE_MATRIX':
            return checkSourceMatrixNotification(objects.sourceMatrixes);
        case 'SOURCE_SELECTOR':
            return checkSourceSelectorNotification(objects.sourceSelectors);
        case 'ZONE':
            return checkZoneNotification(objects.zones, objects.processors);
        case 'SPEAKER':
            return checkSpeakerNotification(objects.speakers);
        case 'METER':
            return checkMeterNotification(objects.meters);
        case 'SENSOR':
            return checkSensorNotification(objects.sensors);
        case 'DOCUMENTATION':
            return checkDocumentationNotification(objects.heatmapFloors);
        case 'CALIBRATION':
            return checkCalibrationNotification(objects.zones);
        default:
            return false;
    }
};

function checkHubNotification(hubs) {
    if (!hubs?.length) return true;
    return hubs.filter(hub => !hasZigbeeNetwork(hub)).length !== 0;
}

function checkProcessorNotification(processors) {
    return processors?.length === 0;
}

function checkSourceNotification(sources, sourceSelectors) {
    if (!sources?.length && sourceSelectors?.length) return true;
    return false;
}

function checkSourceMatrixNotification(sourceMatrixes) {
    return sourceMatrixes?.length === 0;
}

function checkSourceSelectorNotification(sourceSelectors) {
    return false;
}

function checkZoneNotification(zones, processors) {

    var notify = false;
    if (!zones?.length) notify = true;
    else {
        for (let zone of zones) {
            if (!zone.objectAddress && !singleSpeakerSystems.includes(processors.find(p => p.processorId === zone.processorId)?.type)) {
                notify = true;
                break;
            }
        }
    }
    return notify;
}

function checkSpeakerNotification(speakers) {
    var notify = false;
    if (!speakers?.length) notify = true;
    else if (!speakers.find(speaker => speaker.zoneId)) notify = true;
    return notify;
}

function checkMeterNotification(meters) {
    return false;
}

function checkSensorNotification(sensors) {
    return sensors?.length === 0;
}

function checkDocumentationNotification(heatmapFloors) {
    return !heatmapFloors?.length;
}

function checkCalibrationNotification(zones) {
    return zones.find(zone => zone.shouldRegulate && !zone.calibrationPoints && !zone.hide ) || zones.length === 0;
}

export default tabNotificationCheck;

export {
    checkHubNotification,
    checkProcessorNotification,
    checkSourceNotification,
    checkSourceMatrixNotification,
    checkSourceSelectorNotification,
    checkZoneNotification,
    checkSpeakerNotification,
    checkMeterNotification,
    checkSensorNotification,
    checkDocumentationNotification,
    checkCalibrationNotification
}