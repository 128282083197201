import React, { useEffect, useState } from 'react';
import Button from '../UiComponents/Button';
import { AnimationOverlay, SetupStatusContainer, SetupStepContainer, SkipButton, SkipButtonContainer, StepManagerContainer, ConnectionConfirmatoinContainer } from './SetupStepContainers';
import theme from '../../UI/theme';
import { HubToEthAnimation } from '../../Animations/LottieAnimations';


const EthernetSetup = ({ hubBluetoothConnection, setSetupStep, SETUP_STEP, ethernet, setEthernet, wifi}) => {

    const [pending, setPending] = useState(false);

    useEffect(() => {
        syncData();
        hubBluetoothConnection.subscribeEthernetData(subscriptionCallback);
    }, []);

    useEffect(() => () => {
        hubBluetoothConnection.unsubscribeEthernetData(subscriptionCallback);
    }, []);

    function subscriptionCallback(event) {
        setPending(false);
        const value = new TextDecoder().decode(event.target.value);
        var parsedData = hubBluetoothConnection.parse(value);
        const notification = parsedData.notification;
        const data = parsedData.data;

        switch (notification) {
            case 'NETWORK':
                updateEthernet(data);
                break;

            case 'PENDING':
                setPending(true);
                break;

            default:
                break;
        }
    }

    function updateEthernet(data) {
        var updatedEthernet = {...ethernet};
        updatedEthernet.ip = data.ip;
        updatedEthernet.internet = data.internet;
        updatedEthernet.server = data.server;
        setEthernet(updatedEthernet);
    }

    async function syncData() {
        const ethernetInfo = await hubBluetoothConnection.getEthernetData();
        if (ethernetInfo) {
            setEthernet({ip: ethernetInfo.ip, internet: ethernetInfo.internet});
        }
    }

    function next() {;
        setSetupStep(SETUP_STEP.INSTALLER);
    }

    function back() {
    }

    function skip() {
        setSetupStep(SETUP_STEP.WIFI);
    }

    return (
        <SetupStepContainer>
            <AnimationOverlay/>
            <h2>Network</h2>
            {!pending && !ethernet.ip && <h4 style={{margin: '0px 0px 10px 0px', textAlign: 'center'}}>Connect Hub to a cabled network</h4>}
            {pending && <h4 style={{margin: '0px 0px 10px 0px', textAlign: 'center'}}>Connecting...</h4>}
            {!pending && ethernet.ip && <h4 style={{margin: '0px 0px 10px 0px', textAlign: 'center'}}>Connected</h4>}
            
            <p style={{margin: '0px 0px 10px 0px', textAlign: 'center'}}>This should be the same network as the music system</p>

            <SetupStatusContainer >
                {ethernet.ip || pending?
                    <ConnectionConfirmatoinContainer>
                        <p>Ethernet IP:</p>
                        {ethernet.ip ? <p style={{color: theme.colors.greenEnergy}}>{ethernet.ip}</p>
                        : <>{pending? <p style={{color: theme.colors.mellowYellow}}>Connecting...</p> : <p style={{color: theme.colors.raspberryRed}}>Undefined</p>}</> } 
                        <p >Local net:</p>
                        {ethernet.ip ? <p style={{color: theme.colors.greenEnergy}}>{' Connected'}</p> 
                        : <>{pending? <p style={{color: theme.colors.mellowYellow}}>Connecting...</p> : <p style={{color: theme.colors.raspberryRed}}>Not Connected</p>}</> } 
                        <p>Internet:</p>
                        {ethernet.internet ? <p style={{color: theme.colors.greenEnergy}}>{' Connected'}</p>
                        : <>{pending? <p style={{color: theme.colors.mellowYellow}}>Connecting...</p> : <p style={{color: theme.colors.raspberryRed}}>Not Connected</p>}</> } 
                        <p>Server:</p>
                        {ethernet.server ? <p style={{color: theme.colors.greenEnergy}}>{' Connected'}</p>
                        : <>{pending? <p style={{color: theme.colors.mellowYellow}}>Connecting...</p> : <p style={{color: theme.colors.raspberryRed}}>Not Connected</p>}</> } 
                    </ConnectionConfirmatoinContainer>
                :
                    <>
                        <HubToEthAnimation
                            size={275}
                        />
                        <p style={{marginTop: '30px', textAlign: 'center'}}>Waiting, please connect to a cabled network...</p>
                    </>
                }
            </SetupStatusContainer>

            {(ethernet.ip && !ethernet.internet) && 
                <p style={{marginTop: '30px', textAlign: 'center', color: theme.colors.raspberryRed}}>This network has no internet connection. <br/>It's recommended to use a network with internet access</p>
            }

            {(ethernet.internet && !ethernet.server) &&
                <p style={{marginTop: '30px', textAlign: 'center', color: theme.colors.raspberryRed}}>This network has internet, but Waved Hub can not reach its server. <br/>It might be blocked by a firewall, please contact the owner of this network</p>
            }

            {ethernet.server &&
                <Button disabled={false} primary onClick={next}>Next Step</Button>
            }

            <StepManagerContainer>
                <Button disabled tertiary small onClick={back}>Back</Button>
                <Button disabled={false} tertiary small onClick={skip}>Skip</Button>
            </StepManagerContainer>
        </SetupStepContainer>
    );
};

export default EthernetSetup;