import React, { useEffect, useState } from 'react';
import Button from '../UiComponents/Button';
import { SetupStatusContainer, SetupStepContainer, SkipButton, SkipButtonContainer, StepManagerContainer, ConnectionConfirmatoinContainer, AnimationOverlay } from './SetupStepContainers';
import styled from 'styled-components';
import InputField from '../UiComponents/InputField';
import theme from '../../UI/theme';
import IconLogo from '../UiComponents/IconLogo';
import { UserListContainer } from './SetupStepContainers';

const NewOrExistingClientContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
`


const ClientSetup = ({ hubBluetoothConnection, setSetupStep, SETUP_STEP, setupNewClient, setSetupNewClient, client, setClient, ethernet}) => {

    const [newVenueName, setNewVenueName] = useState('');
    const [missingClientID, setMissingClientID] = useState(false);
    const [dataPending, setDataPending] = useState(false);
    const [clientNotFound, setClientNotFound] = useState(false);
    const [availableVenues, setAvailableVenues] = useState([]);
    
    const dataPendingRef = React.useRef(dataPending);
    dataPendingRef.current = dataPending;

    useEffect(() => {
        syncClientData();
        syncAvailableVenues();
        hubBluetoothConnection.setAvailableVenuesData({cmd: 'fetch from server'});
        hubBluetoothConnection.subscribeClientData(subscriptionCallback);
        hubBluetoothConnection.subscribeAvailableVenuesData(availableVenuesSubscriptionCallback);
    }, []);

    useEffect(() => () => {
        hubBluetoothConnection.unsubscribeClientData(subscriptionCallback);
        hubBluetoothConnection.unsubscribeAvailableVenuesData(availableVenuesSubscriptionCallback);
    }, []);

    function subscriptionCallback(event) {
            const value = new TextDecoder().decode(event.target.value);
            var parsedData = hubBluetoothConnection.parse(value);
            setTimeout(() => {syncClientData();}, 2000);
    }

    function availableVenuesSubscriptionCallback(event) {
        const value = new TextDecoder().decode(event.target.value);
        const venues = hubBluetoothConnection.parse(value);
        if (venues.length > 0) {
            setAvailableVenues(venues);
        } else setTimeout(() => {syncAvailableVenues();}, 2000);
    }

    function toggleSetupNewClient(state) {
        if (dataPending) return;
        if (setupNewClient === state) return;
        setSetupNewClient(state);
        //if (setupNewClient === null) return; // why is this here?
        if (availableVenues.length === 0) hubBluetoothConnection.setAvailableVenuesData({cmd: 'fetch from server'});
        setNewVenueName('');
        setClientNotFound(false);
        setDataPending(true);
        setClient(null);
        const client = {id: 'WHIPE'};
        hubBluetoothConnection.setClientData(client);
        setTimeout(() => {
            if (dataPendingRef.current) {
                syncClientData();
            }
        }, 4250);
    }

    function setNewClient() {
        if (dataPending) return;
        setDataPending(true);
        const client = {
            name: newVenueName,
            createNew: true
        };
        hubBluetoothConnection.setClientData(client);
        setTimeout(() => {
            if (dataPendingRef.current) syncClientData();;
        }, 4250);

    }

    function findClient(custommerId) {
        if (dataPending) return;
        var id = Number(custommerId);
        if (isNaN(id)) {
            setMissingClientID(true);
            return;
        }else setMissingClientID(false);
        setDataPending(true);
        var client = {id: id};
        hubBluetoothConnection.setClientData(client);

        setTimeout(() => {
            if (dataPendingRef.current) syncClientData();;
        }, 4250);

    }

    async function syncClientData() {
        var foundClient = await hubBluetoothConnection.getClientData();
        if (foundClient?.id && foundClient?.name) {
            setClient(foundClient);
            if (foundClient.createNew) setSetupNewClient(true);
            else setSetupNewClient(false);
            setDataPending(false);
        } else if (foundClient?.id === null) {
            setClient(null);
            setDataPending(false);
        }
    }

    async function syncAvailableVenues() {
        const foundVenues = await hubBluetoothConnection.getAvailableVenuesData();
        if (foundVenues.length > 0) {
            setAvailableVenues(foundVenues);
            setDataPending(false);
        } else setDataPending(false);
        
    }

    function retryExistingVenues() {
        setDataPending(true);
        hubBluetoothConnection.setAvailableVenuesData({cmd: 'fetch from server'})
        setTimeout(() => {
            if (dataPendingRef.current) syncAvailableVenues();
        }, 4250);

    }

    function next() {
        //setSetupStep(SETUP_STEP.USER);
        setSetupStep(SETUP_STEP.COMPLETE);
    }

    function back() {
        setSetupStep(SETUP_STEP.INSTALLER);
    }

    return (
        <SetupStepContainer>
            <AnimationOverlay/>
            <h2>Client</h2>
            <p>Is this a new or exsisting project?</p> 
            <NewOrExistingClientContainer style={{maxWidth: '300px'}}>
                <Button disabled={dataPending} style={{width: '75px'}} primary={setupNewClient===true} tertiary={setupNewClient !== true} small onClick={() => toggleSetupNewClient(true)}>New</Button>
                or
                <Button disabled={dataPending} style={{width: '75px'}} primary={setupNewClient===false} tertiary={setupNewClient!==false} small onClick={() => toggleSetupNewClient(false)}>Existing</Button>
            </NewOrExistingClientContainer>

            {setupNewClient===true && !client? <>
            
                <p style={{marginTop:'60px', width: '100%', maxWidth: '300px'}}>Venue Name
                    <InputField
                        value={newVenueName}
                        onChange={(e) => {setNewVenueName(e.target.value)}}
                        onBlur={(e) => {setNewVenueName(e.target.value)}}
                        placeholder='venue'
                    />
                </p>
                <Button primary small disabled={!newVenueName || dataPending} onClick={setNewClient}>Set Venue Name</Button>
            </>:null}

            {setupNewClient===false && !client?.id && !dataPending? <>
                {availableVenues.length > 0?<>
                    <SetupStatusContainer>
                        <p style={{marginTop: '30px'}}>Available Venues:</p>
                        <UserListContainer>
                            <h4 style={{borderBottom: `1px solid ${theme.colors.greenEnergy}`, width: '100%', textAlign: 'center'}}>Venue</h4>
                            <h4 style={{borderBottom: `1px solid ${theme.colors.greenEnergy}`, width: '100%', textAlign: 'center'}}>Id</h4>
                            <h4 style={{borderBottom: `1px solid ${theme.colors.greenEnergy}`, width: '100%', textAlign: 'center', color: 'rgba(0, 0, 0, 0)'}}>x</h4>
                            {availableVenues.map((venue, index) => {
                                return ([
                                    <p key={(3*index +1)}>{venue.companyName}</p>,
                                    <p key={(3*index +2)}>{venue.customerId}</p>,
                                    <Button primary small key={(3*index +3)} style={{cursor: 'pointer'}} onClick={() => findClient(venue.customerId)}>Select</Button>
                                ]);
                            })}
                        </UserListContainer>
                    </SetupStatusContainer>
                </>:
                    <SetupStatusContainer>
                        <p style={{marginTop: '30px', color: theme.colors.raspberryRed}}>No clients found for this installer account</p>
                        <Button primary small onClick={retryExistingVenues}>Retry</Button>
                    </SetupStatusContainer>
                }
            </>: null}

            {(client?.id && client?.name && !dataPending)?
                <SetupStatusContainer>
                    <h4 >{client?.createNew? 'Verify Venue Name: ': 'Continue with:'}</h4>
                    <ConnectionConfirmatoinContainer>
                        {client?.createNew? null : <p>ID:</p>}
                        {client?.createNew? null : <p>{client?.id}</p>}
                        <p>Venue:</p>
                        <p>{client?.name}</p>
                    </ConnectionConfirmatoinContainer>
                    <Button style={{marginTop: '70px'}} primary onClick={next}>Next Step</Button>
                    <Button style={{marginTop: '70px'}} tertiary small onClick={() => {setClient(null); setNewVenueName('')}}>{client?.createNew? 'Change name' : 'Choose another'}</Button>
                </SetupStatusContainer>
            :null}

            {dataPending? 
                <SetupStatusContainer>
                    <p>One moment...</p>
                    <IconLogo preloader/>
                </SetupStatusContainer>
            : null}

            <StepManagerContainer>
                <Button disabled={false} tertiary small onClick={back}>Back</Button>
                {/*<Button disabled={!client?.id || !client?.name || dataPending} primary small onClick={next}>Next</Button>*/}
            </StepManagerContainer>
            
        </SetupStepContainer>
    );
};

export default ClientSetup;