import React, { useEffect, useState } from "react";
import styled, {css} from "styled-components";
import theme from "../../../../../UI/theme";
import { Placeholder } from "../../../table/styles/TableStyles";
import { PairSensorAnimation, SwitchTypeAnimation, TurnOnSensorAnimation } from "../../../../../Animations/LottieAnimations";

const FadeInContainer = styled.div`
    opacity: 1;
    animation: fadeIn 0.8s ease-in forwards;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${theme.colors.darkSpace};
    z-index: 100;
    

    @keyframes fadeIn {
        to {
            opacity: 0;
        }
    }
`;

const HeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding-bottom: 10px;
`

const ExplenationStepsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;

    @media only screen and (max-width: ${theme.screenSizes.large}px) {
        flex-direction: column;
        gap: 20px;
    }

`

const ExplenationStepGrid = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 5px;

    padding: 5px 10px;
    border: 2px solid ${theme.colors.darkSpace90};
    width: 100%;
    height: 105px;

    @media only screen and (max-width: ${theme.screenSizes.large}px) {
        grid-template-columns: 1fr 1fr;
    }

`
const ExplenationStep = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    height: 100%;

    h4 {
        font-size: 1.0rem;
        font-weight: bold;
        margin: 0;
    }

    p {
        font-size: 0.85rem;
        margin: 0;
        font-weight: bold;
    }

    ul {
        padding: 0px 0px 0px 20px;
        margin: 0;
        font-size: 0.8rem;
    }

    li {
    }

 `  

const AnimationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

const SensorSetupInfo = ({setShowInfoHeaderHandler, time}) => {

    return (
        <Placeholder style={{position: 'relative'}}>
            <FadeInContainer/>
            <HeaderRow>
                <h3>Pairing mode enabled:</h3>
                <Timer
                    setShowInfoHeaderHandler={setShowInfoHeaderHandler}
                    time={time}
                />
            </HeaderRow>
            <ExplenationStepsContainer>
                <ExplenationStepGrid>
                    <ExplenationStep>
                        <h4>1. Turn sensor on</h4>
                        <p>Press left button</p>
                        <ul>
                            <li><span style={{color: theme.colors.sensorBlinkBlue }}>Blue</span> logo &#8594; It's a Router</li>
                            <li><span style={{color: theme.colors.sensorBlinkOrange }}>Orange</span> logo &#8594; It's an End Device</li>
                        </ul>
                    </ExplenationStep>
                    <AnimationContainer>
                        <TurnOnSensorAnimation size={90} />
                    </AnimationContainer>
                </ExplenationStepGrid>


                <ExplenationStepGrid>
                    <ExplenationStep>
                        <h4>2. Set to correct type</h4>
                        <p>Hold right 5 seconds to switch</p>
                        <ul>
                            <li><span style={{color: theme.colors.sensorBlinkOrange }}>Orange</span> &#8594; <span style={{color: theme.colors.sensorBlinkBlue }}>Blue</span>: Router</li>
                            <li><span style={{color: theme.colors.sensorBlinkBlue }}>Blue</span> &#8594; <span style={{color: theme.colors.sensorBlinkOrange }}>Orange</span>: End Device</li>
                            <li>End Device only when on battery!</li>
                        </ul>
                    </ExplenationStep>
                    <AnimationContainer>
                        <SwitchTypeAnimation size={90} />
                    </AnimationContainer>
                </ExplenationStepGrid>

                <ExplenationStepGrid>
                    <ExplenationStep>
                        <h4>3. Pair the Sensor</h4>
                        <p>Double click right button</p>
                        <ul>
                            <li>Logo flashes <span style={{color: theme.colors.mellowYellow }}>yellow</span></li>
                            <li>Sensor paired and visible in app</li>
                        </ul>
                    </ExplenationStep>
                    <AnimationContainer>
                        <PairSensorAnimation size={90} />
                    </AnimationContainer>
                </ExplenationStepGrid>
                
            </ExplenationStepsContainer>
        </Placeholder>
    )
}

export default SensorSetupInfo;



const TimerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.0rem;
    font-weight: bold;
    color: ${props => props.numberColor? props.numberColor : ''};
    width: 60px;
    height: 60px;
    background-color: ${theme.colors.darkSpace};
    border-radius: 50%;
    border: 3px solid ${theme.colors.darkSpace90};
    position: relative;
    
    &: after {
        content: '';
        display: block;
        position: absolute;
        left: -6px;
        top: -6px;
        right: -6px;
        bottom: -6px;
        background-color: transparent;
        background-image: conic-gradient(${theme.colors.greenEnergy}, ${theme.colors.greenEnergy} ${props => (props.totalTime && props.elapsedTime)? 100 - 100*props.elapsedTime/props.totalTime : 0 }%, transparent ${props => (props.totalTime && props.elapsedTime)? 100 -100*props.elapsedTime/props.totalTime: 0}%);
        z-index: -100;
        border-radius: 50%;
    }
`;


const Timer = ({setShowInfoHeaderHandler, time}) => {

    const [elapsedTime, setElapsedTime] = useState(0);

    const [second, setSecond] = useState(time.sec < 10 ? `0${time.sec}` : time.sec.toString());
    const [minute, setMinute] = useState(time.min < 10 ? `0${time.min}` : time.min.toString());

    const [readableTime, setReadableTime] = useState(`${minute}:${second}`);

    const [numberColor, setNumberColor] = useState('');

    useEffect(() => {
        formatTime();
        setElapsedTime(180 - 60*time.min - time.sec);
    }, [time]);

    const formatTime = () => {
        setSecond(time.sec < 10 ? `0${time.sec}` : time.sec.toString());
        setMinute(time.min < 10 ? `0${time.min}` : time.min.toString());
        setReadableTime(`${minute}:${second}`);
        if(time.min === 0 && time.sec < 10) {
            setNumberColor(theme.colors.raspberryRed);
        } else if (readableTime === '00:00' || readableTime.includes('-')) {
            setShowInfoHeaderHandler(false);
        }
    }

    return (
        <TimerContainer numberColor={numberColor} totalTime={180} elapsedTime={elapsedTime}> {/* elapsedTime={180-60*min-sec} */}
            {readableTime}
        </TimerContainer>
        
    )
}