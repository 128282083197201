import axios from 'axios';
import { getHostname } from './server/server'

const hostname = getHostname();
const path = '/v2/user';

async function getUser(userId) {
    const res = await axios.get(`${hostname}${path}?userId=${userId}`);
    return res.data;
}

async function getCurrentUser() {
    const res = await axios.get(`${hostname}${path}/current?`);
    return res.data;
}

async function updateUser(user) {
    const res = await axios.put(`${hostname}${path}`, user);
    return res.data;
}

async function updateUserRole(user) {
    const res = await axios.put(`${hostname}${path}/role`, user);
    return res.data;
}

async function addUser(user) {
    const res = await axios.post(`${hostname}${path}`, user);
    return res.data;
}

async function deleteUser(userId) {
    const res = await axios.delete(`${hostname}${path}?userId=${userId}`);
    return res.data;
}

async function sendPasswordResetLink(email) {
    const res = await axios.get(`${hostname}${path}/passwordReset?email=${email}`);
    return res.data;
}

async function updatePassword(password, passwordResetId) {
    const res = await axios.put(`${hostname}${path}/password`, { password, passwordResetId });
    return res.data;
}

async function sendEmailChangeRequest(email) {
    const res = await axios.post(`${hostname}${path}/emailChangeRequest`, { email });
    return res.data;
}

async function sendEmailChangeVerification(oldEmailVerificationCode, newEmailVerificationCode) {
    const res = await axios.post(`${hostname}${path}/emailChangeVerification`, { oldEmailVerificationCode, newEmailVerificationCode });
    return res.data;
}

async function inviteUser(email, customerId) {
    const res = await axios.post(`${hostname}${path}/invite`, { email, customerId });
    return res.data;
}

async function reiniviteUser(email, customerId) {
    const res = await axios.post(`${hostname}${path}/reinvite`, { email, customerId });
    return res.data;
}

async function signUpUser(user, signUpRequestId) {
    const res = await axios.post(`${hostname}${path}/signUp`, { ...user, signUpRequestId });
    return res.data;
}


export {
    getUser,
    getCurrentUser,
    updateUser,
    updateUserRole,
    addUser,
    deleteUser,
    sendPasswordResetLink,
    updatePassword,
    sendEmailChangeRequest,
    sendEmailChangeVerification,
    inviteUser,
    reiniviteUser,
    signUpUser
}