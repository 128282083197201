import React from "react";
import LottieBaseAnimation from "./LottieBaseAnimation";

import powerHubAnimation from "./json/PowerHub.json";
import hubToEthAnimation from "./json/HubToEth.json";
import PairSensorAnim from "./json/PairSensorAnim.json";
import SwitchTypeAnim from "./json/SwitchTypeAnim.json";
import TurnOnSensorAnim from "./json/TurnOnSensorAnim.json";

const PowerHubAnimation = ({size, height, width}) => {
    return (
        <LottieBaseAnimation
            height={size || height || 400 }
            width={size || width || 400}
            animation={powerHubAnimation}
        />
    );
}

const HubToEthAnimation = ({size, height, width}) => {
    return (
        <LottieBaseAnimation
            height={size || height || 400 }
            width={size || width || 400}
            animation={hubToEthAnimation}
        />
    );
}

const PairSensorAnimation = ({size, height, width}) => {
    return (
        <LottieBaseAnimation
            height={size || height || 400 }
            width={size || width || 400}
            animation={PairSensorAnim}
        />
    );
}

const SwitchTypeAnimation = ({size, height, width}) => {
    return (
        <LottieBaseAnimation
            height={size || height || 400 }
            width={size || width || 400}
            animation={SwitchTypeAnim}
        />
    );
}

const TurnOnSensorAnimation = ({size, height, width}) => {
    return (
        <LottieBaseAnimation
            height={size || height || 400 }
            width={size || width || 400}
            animation={TurnOnSensorAnim}
        />
    );
}

export {
    PowerHubAnimation,
    HubToEthAnimation,
    PairSensorAnimation,
    SwitchTypeAnimation,
    TurnOnSensorAnimation,
}