import { deleteZone, updateZones } from '../../../../scripts/zone';
import { getDefaultId } from '../../table/tableUtils';
import ProcessorChoiceField from '../../fields/ProcessorChoiceField';
import NumberField from '../../fields/NumberField';
import { DisplayField } from '../../fields/Styles';
import NameField from '../../fields/NameField';
import { ZoneIcon } from '../../styling/Icons';
import CheckboxField from '../../fields/CheckBoxField';
import MuteAddressField, { hideMuteAddressField } from './fields/MuteAddressField';
import HierarchyTypeChoiceField from './fields/HierarchyTypeChoiceField';
import { addNewZone } from './zoneUtils';
import ObjectAddressField from './fields/ObjectAddressField';
import MasterZoneIdChoiceField, { hideMasterZoneIdField } from './fields/MasterZoneIdChoiceField';
import SourceSelectorChoiceField, { getSourceSelectorRequiredState } from './fields/SourceSelectorChoiceField';
import BackupZoneIdChoiceField from './fields/BackupZoneIdChoiceField';
import MeterChoiceField from './fields/MeterChoiceField';
import ShowMeterTabButton from './actions/ShowMeterTabButton';
import { actionTypes, requiredStates } from '../../utils/installationTypes';
import Button from '../../../UiComponents/Button';
import { Placeholder } from '../../table/styles/TableStyles';
import AddObjectButton from '../../table/AddObjectButton';
import { installationTabs } from '../../installationTypes';
import { InfoIcon } from '../../../UiComponents/Icons';
import theme from '../../../../UI/theme';
import { singleSpeakerSystems } from '../speaker/speakerUtils';
import { disable } from 'mixpanel-browser';
import { checkZoneNotification } from '../../utils/tabNotificationCheck';

const idField = 'zoneId';

export const getZoneTableProps = ({ customerId, zones, processors, sourceSelectors, meters, hubs, setSelectedTab }) => {


    const getColumns = (zones) => [{
        key: 'zoneName',
        displayName: 'Name',
        form: true,
        width: '150px',
        formValidate: (zone) => {
            return zone.zoneName ? null : { type: 'ERROR', message: 'Name is required' };
        },
        render: (zone, updateZone, cellControl) => (
            <NameField
                object={zone}
                updateObject={updateZone}
                objects={zones}
                idField={'zoneId'}
                nameField={'zoneName'}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'processorId',
        displayName: 'Processor',
        width: '150px',
        form: true,
        formValidate: (zone) => {
            return zone.processorId ? null : { type: 'ERROR', message: 'Processor is required' };
        },
        getSuggested: () => getDefaultId(processors, 'processorId'),
        render: (zone, updateZone, cellControl) => (
            <ProcessorChoiceField
                object={zone}
                processors={processors}
                updateObject={updateZone}
                cellControl={cellControl}
            />
        )
    }, {
        key: 'sourceSelectorId',
        displayName: 'Source Selector',
        width: '150px',
        form: true,
        getSuggested: () => getDefaultId(sourceSelectors, 'sourceSelectorId'),
        getRequiredState: (zone) => getSourceSelectorRequiredState(zone, sourceSelectors),
        render: (zone, updateZone, cellControl) => (
            <SourceSelectorChoiceField
                zone={zone}
                updateZone={updateZone}
                sourceSelectors={sourceSelectors}
                cellControl={cellControl}
            />
        )
    }, /*{
        key: 'meterId',
        displayName: 'Meter',
        width: '150px',
        form: false,
        render: (zone, updateZone, cellControl) => (
            <MeterChoiceField
                zone={zone}
                updateZone={updateZone}
                meters={meters}
                cellControl={cellControl}
            />
        )
    }, */{
        key: 'objectAddress',
        displayName: <>
            Object Address <InfoIcon
                style={{ fontSize: '16px', color: theme.colors.greenEnergy}}
                onClick={() => {window.open('https://help.waved.co/en/articles/9595553-processor-integrations', "_blank")}}
            />
        </>,
        getRequiredState: (zone) => (!zone.objectAddress && !singleSpeakerSystems.includes(processors.find(p => p.processorId === zone.processorId)?.type))? requiredStates.RECOMMENDED : requiredStates.OPTIONAL,
        width: '300px',
        form: true,
        render: (zone, updateZone, cellControl) => (<>
            {singleSpeakerSystems.includes(processors.find(p => p.processorId === zone.processorId)?.type) ? 
                <DisplayField>{zone.objectAddress || 'Manage from "Speakers"'}</DisplayField>
            :
                <ObjectAddressField
                    zone={zone}
                    updateZone={updateZone}
                    zones={zones}
                    processors={processors}
                    cellState={cellControl.cellState}
                    placeholder={singleSpeakerSystems.includes(processors.find(p => p.processorId === zone.processorId)?.type) ? 'Manage from "Speakers"' : 'Missing object address'}
                />
            }
        </>)
    }, {
        key: 'muteAddress',
        displayName: 'Mute Address',
        width: '300px',
        hide: hideMuteAddressField(zones, processors),
        render: (zone, updateZone, cellControl) => (
            <MuteAddressField
                zone={zone}
                updateZone={updateZone}
                zones={zones}
                processors={processors}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'orderIndex',
        displayName: 'Order',
        width: '50px',
        render: (object, updateObject, cellControl) => (
            <NumberField
                value={object.orderIndex}
                setValue={(value) => updateObject({ orderIndex: value })}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'hide',
        displayName: 'Hide',
        width: '50px',
        render: (zone, updateZone, cellControl) => (
            <CheckboxField
                checked={zone.hide}
                setValue={() => updateZone({ hide: +(!zone.hide) })}
                cellControl={cellControl}
            />
        )
    }, {
        key: 'shouldRegulate',
        displayName: 'Automate',
        width: '50px',
        render: (zone, updateZone, cellControl) => (
            <CheckboxField
                checked={zone.shouldRegulate}
                setValue={() => updateZone({ shouldRegulate: +(!zone.shouldRegulate) })}
                cellControl={cellControl}
            />
        )
    }, {
        key: 'autoResetZoneSettings',
        displayName: 'Autoreset',
        width: '50px',
        render: (zone, updateZone, cellControl) => (
            <CheckboxField
                checked={zone.autoResetZoneSettings}
                setValue={() => updateZone({ autoResetZoneSettings: +(!zone.autoResetZoneSettings) })}
                cellControl={cellControl}
            />
        )
    }, {
        key: 'backupZoneId',
        displayName: 'Backup Zone',
        width: '150px',
        render: (zone, updateZone, cellControl) => (
            <BackupZoneIdChoiceField
                zone={zone}
                updateZone={updateZone}
                zones={zones}
                cellControl={cellControl}
            />
        )
    }, {
        key: 'hierarchyType',
        displayName: 'Hierarchy',
        width: '100px',
        render: (zone, updateZone, cellControl) => (
            <HierarchyTypeChoiceField
                hierarchyType={zone.hierarchyType}
                setValue={(value) => updateZone({ hierarchyType: value })}
                cellControl={cellControl}
            />
        )
    }, {
        key: 'masterId',
        displayName: 'Master Zone',
        width: '150px',
        hide: hideMasterZoneIdField(zones),
        render: (zone, updateZone, cellControl) => (
            <MasterZoneIdChoiceField
                zone={zone}
                zones={zones}
                setValue={(value) => updateZone({ masterId: value })}
                cellControl={cellControl}
            />
        )
    }, {
        key: 'minDbLevel',
        displayName: 'Min. dB',
        width: '60px',
        render: (object, updateObject, cellControl) => (
            <NumberField
                value={object.minDbLevel}
                setValue={(value) => updateObject({ minDbLevel: value })}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'maxDbLevel',
        displayName: 'Max. dB',
        width: '60px',
        render: (object, updateObject, cellControl) => (
            <NumberField
                value={object.maxDbLevel}
                setValue={(value) => updateObject({ maxDbLevel: value })}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'panelLimit',
        displayName: 'Panel Max. %',
        width: '60px',
        render: (object, updateObject, cellControl) => (
            <NumberField
                value={object.panelLimit}
                setValue={(value) => updateObject({ panelLimit: value })}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'disableMusicDom',
        displayName: 'Disable Music Dom.',
        width: '50px',
        render: (zone, updateZone, cellControl) => (
            <CheckboxField
                checked={zone.disableMusicDom}
                setValue={() => updateZone({ disableMusicDom: +(!zone.disableMusicDom) })}
                cellControl={cellControl}
            />
        )
    }];

    const getListItemProps = (zone) => {
        return {
            displayName: zone.zoneName,
            Icon: ZoneIcon
        }
    }

    const saveObjects = async (changedZones) => {
        try {
            await updateZones(changedZones);
        } catch (err) {
            console.log('save error', err);
        }
    }

    const deleteObject = async (zone) => {
        try {
            await deleteZone(zone.zoneId);
        } catch (err) {
            console.log('Error failed to delete zone');
        }
    }

    const addObject = processors.length ? (newZone) => addNewZone(newZone, zones, processors, customerId) : null;

    const getActions = (zone) => [{
        key: 'addMeter',
        type: actionTypes.LIST,
        render: () => (
            <ShowMeterTabButton setSelectedTab={setSelectedTab} />
        )
    }];

    const getPlaceholder = () => {
        return (
            <Placeholder>
                {processors.length ? <>
                        <p>No zones added.</p>
                        <AddObjectButton
                            title='add zone' 
                            objects={zones}
                            onSubmit={addObject}
                            columns={getColumns(zones)}
                            overrideButtonStyle={{
                                title: 'Add zone',
                            }}
                        />
                </> : <>
                    <p>Add a processor before adding zones</p>
                    <Button primary small onClick={() => setSelectedTab(installationTabs.find(tab => tab.key==='PROCESSOR'))}>Go to Processors</Button>
                </>}
            </Placeholder>
        )
    }

    const showNotification = (zone) => {
        console.log('calling showNotificaton', zone);
        return checkZoneNotification([zone], processors);
    }

    return {
        title: 'Zones',
        getColumns: getColumns,
        parentObjects: zones,
        idField,
        saveObjects,
        deleteObject,
        addObject,
        getListItemProps,
        getActions,
        getPlaceholder,
        showNotification
    }
}