import React, { useState, useEffect } from 'react';
import Button from '../UiComponents/Button';
import { SetupStepContainer, ConnectionConfirmatoinContainer, StepManagerContainer, SkipButtonContainer, AnimationOverlay, ReviewContainer, ReviewItem, ReviewItemSummary, ReviewAlternative, SetupStatusContainer } from './SetupStepContainers';
import theme from '../../UI/theme';
import IconLogo from '../UiComponents/IconLogo';
import { useHistory } from 'react-router-dom';

const COMPLEATION_STEPS = {
    SUMMARY: 'SUMMARY',
    PENDING: 'PENDING',
    UPDATING: 'UPDATING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
}


const HubSetupComplete = ({ hubBluetoothConnection, setSetupStep, SETUP_STEP}) => {

    const [ethernet, setEthernet] = useState({ip: null, internet: null});
    const [wifi, setWifi] = useState({ssid: null, ip: null, internet: null});
    const [client, setClient] = useState({id: null, name: null});
    const [installer, setInstaller] = useState({email: null});
    const [users, setUsers] = useState([]);
    const [compleationStep, setCompleationStep] = useState(COMPLEATION_STEPS.PENDING);
    const [newClientId, setNewClientId] = useState(null);
    const history = useHistory();



    useEffect(() => {
        fetchHubData();
        hubBluetoothConnection.subscribeCompleteData(subscriptionCallback);
    },[]);

    useEffect(() => () => {
        try {
            hubBluetoothConnection.unsubscribeCompleteData(subscriptionCallback);
        } catch (error) {
            console.log('Unsubscribe failed');
        }
    }, []);

    function subscriptionCallback(event) {
        const value = new TextDecoder().decode(event.target.value);
        var parsedData = hubBluetoothConnection.parse(value);
        handleSetupResult(parsedData.data);
    }

    function handleSetupResult(result) {
        if (result?.success || true) {
            setTimeout(() => {
                setCompleationStep(COMPLEATION_STEPS.SUCCESS);
                setNewClientId(result?.client?.id);
            }, 2700);
        } else {
            setCompleationStep(COMPLEATION_STEPS.FAILED);
            // handle it!
        }
    }

    function fetchHubData() {
        const p1 = getEthernetData();
        const p2 = getWifiData();
        const p3 = getClientData();
        const p4 = getInstallerData();
        const p5 = getUsersData();

        Promise.all([p1, p2, p3, p4, p5]).then(() => {
            setCompleationStep(COMPLEATION_STEPS.SUMMARY);
        });

    }

    async function getEthernetData() {
        const foundEthernet = await hubBluetoothConnection.getEthernetData();
        if (foundEthernet) setEthernet(foundEthernet);
        else setEthernet({ip: null, internet: null});
    }

    async function getWifiData() {
        const foundWifi = await hubBluetoothConnection.getWifiData();
        if (foundWifi) setWifi(foundWifi);
        else setWifi({ssid: null, ip: null, internet: null});
    }

    async function getClientData() {
        const foundClient = await hubBluetoothConnection.getClientData();
        if (foundClient) setClient(foundClient);
        else setSetupStep(SETUP_STEP.CLIENT);
    }

    async function getInstallerData() {
        const foundInstaller = await hubBluetoothConnection.getInstallerData();
        if (foundInstaller) setInstaller(foundInstaller);
        else setInstaller({email: null});
    }

    async function getUsersData() {
        const foundUsers = await hubBluetoothConnection.getUsersData();
        if (foundUsers) setUsers(foundUsers);
        else setUsers([]);
    }

    function next() {
        
    }

    function back() {
        //setSetupStep(SETUP_STEP.USER);
        setSetupStep(SETUP_STEP.CLIENT);
    }

    function goToEthernet() {
        setSetupStep(SETUP_STEP.ETHERNET);
    }

    function goToWifi() {
        setSetupStep(SETUP_STEP.WIFI);
    }

    function goToInstaller() {
        setSetupStep(SETUP_STEP.INSTALLER);
    }

    function goToUser() {
        setSetupStep(SETUP_STEP.USER);
    }

    async function goToInstallView(update=false) {
        setCompleationStep(COMPLEATION_STEPS.UPDATING);
        await hubBluetoothConnection.cleanupAndContinue({update: update});
        setTimeout(() => {
            hubBluetoothConnection.unsubscribeCompleteData(subscriptionCallback);
            hubBluetoothConnection.disconnect();
            setCompleationStep(COMPLEATION_STEPS.SUCCESS);
            if (newClientId) history.push(`/installation?customerId=${newClientId}`);
            else history.push(`/installation`);
        }, 1000);
    }

    function completeSetup() {
        setCompleationStep(COMPLEATION_STEPS.PENDING);
        hubBluetoothConnection.setCompleteData({complete: true});
    }

    return (
        <SetupStepContainer>
            <AnimationOverlay/>
            <h2>Review and complete!</h2>

            {compleationStep === COMPLEATION_STEPS.SUMMARY ?<>
                <ReviewContainer>

                    <ReviewItem>
                        <h5>Client</h5>
                        <ReviewItemSummary>
                            <p>Name:</p>
                            {client.name ? <p>{client.name}</p> : <p>Not connected</p>}
                            {client.id && client.id != 'CREATE_NEW' ? <p>Id:</p> : null}
                            {client.id && client.id != 'CREATE_NEW' ? <p>{client.id}</p> : null}
                            
                        </ReviewItemSummary>
                    </ReviewItem>

                    <ReviewItem>
                        <h5>Installer</h5>
                        {installer.email ?
                            <ReviewItemSummary>
                                <p>Email:</p>
                                <p>{installer.email}</p>
                            </ReviewItemSummary>
                        :
                            <ReviewAlternative>
                                <p>No installer added</p>
                                <span><a onClick={goToInstaller}>Add installer?</a> (recommended)</span>
                            </ReviewAlternative>
                        }
                    </ReviewItem>

                    {/*
                    <ReviewItem>
                        <h5>Users</h5>
                        {users.length ?
                            <ReviewItemSummary>
                                {users.map((user, index) => {
                                    return ([
                                        <p key={(3*index +1)}>{user.accessLevel.label}</p>,
                                        <p key={(3*index +2)}>{user.email}</p>
                                    ]);
                                })}
                            </ReviewItemSummary>
                        :
                            <ReviewAlternative>
                                <p>No users added</p>
                                <a onClick={goToUser}>Add users</a>
                            </ReviewAlternative>
                        }
                    </ReviewItem>
                    */}

                    {wifi.ip?
                        <ReviewItem>
                            <h5>Wifi</h5>
                            {wifi.ip?
                                <ReviewItemSummary>
                                    <p>Ip:</p>
                                    {wifi.ip ? <p>{wifi.ip}</p> : <p>Not connected</p>}
                                    <p>Name:</p>
                                    {wifi.ssid ? <p>{wifi.ssid}</p> : <p>Not connected</p>}
                                    <p>Internet:</p>
                                    {wifi.internet ? <p>Connected</p> : <p>Not connected</p>}
                                </ReviewItemSummary>
                            :
                                <ReviewAlternative>
                                    <p>No wifi connected</p>
                                    <span><a onClick={goToWifi}>Connect to a wifi network</a> (optional) </span>
                                </ReviewAlternative>
                            }
                        </ReviewItem>
                    : null }

                    <ReviewItem>
                        {ethernet.ip?<h5>Cabled network</h5>:<h5 style={{color: theme.colors.raspberryRed}}>Cabled network</h5>}
                        
                        {ethernet.ip?
                            <ReviewItemSummary>
                                <p>Ip:</p>
                                {ethernet.ip ? <p>{ethernet.ip}</p> : <p>Not connected</p>}
                                <p>Local net:</p>
                                {ethernet.ip ? <p>Connected</p> : <p>Not connected</p>}
                                <p>Internet:</p>
                                {ethernet.internet ? <p>Connected</p> : <p>Not connected</p>}
                                <p>Server:</p>
                                {ethernet.server ? <p>Connected</p> : <p>Not connected</p>}
                            </ReviewItemSummary>
                        :
                            <ReviewAlternative>
                                <p>
                                    The hub is not connected to a cabled network! <br></br>
                                    This is strongly recommended.
                                </p>
                                <a onClick={goToEthernet}>Connect to a cabled network</a>
                            </ReviewAlternative>
                        }
                    </ReviewItem>

                    {ethernet.ip? <Button style={{ marginTop: 'auto', alignSelf: 'center'}} disabled={false} primary onClick={completeSetup}>Complete Hub Setup</Button>
                    : <Button style={{ marginTop: 'auto' }} disabled={false} tertiary small onClick={completeSetup}>Complete Hub Setup</Button>}
            

                </ReviewContainer>          
            </>:null}

            {compleationStep === COMPLEATION_STEPS.SUCCESS ?
                <SetupStatusContainer>
                    <h4>Hub Setup Complete!</h4>
                    <p style={{textAlign: 'center'}}>Continue the installation in application.</p>
                    <p>You might need to log in first</p>
                    <Button  primary onClick={() => goToInstallView(true)}>Update and Continue</Button>
                    <Button style={{marginTop: '30px'}} tertiary small onClick={() => goToInstallView()}>Continue without Updating</Button>
                </SetupStatusContainer>
            : null}

            {compleationStep === COMPLEATION_STEPS.FAILED ?
                <SetupStatusContainer>
                    <h4>Hub Setup Failed!</h4>
                    <Button primary small onClick={() => setCompleationStep(COMPLEATION_STEPS.SUMMARY)}>Retry</Button>
                </SetupStatusContainer>
            : null}

            {compleationStep === COMPLEATION_STEPS.PENDING || compleationStep === COMPLEATION_STEPS.UPDATING?
                <SetupStatusContainer>
                    <p>One moment...</p>
                    <IconLogo preloader/>
                </SetupStatusContainer>
            :null}

            <StepManagerContainer>
                <Button disabled={false} tertiary small onClick={back}>Back</Button>
                {/*<Button disabled={true} primary small onClick={next}>Next</Button>*/}
            </StepManagerContainer>
        </SetupStepContainer>
    );
};

export default HubSetupComplete;