import { useState } from "react";
import Button from "../../../UiComponents/Button"
import Modal from "../../../UiComponents/Modal";
import { ButtonRowContainer, ModalContainer, ModalHeader, ModalText } from "../../../panel/ModalStyles";
import theme from "../../../../UI/theme";
import { reiniviteUser } from "../../../../scripts/user";

const ReinviteUserButton = ({ permissionObject, refreshWithUpdate }) => {
    const [showModal, setShowModal] = useState(false);
    const [invitationSent, setInvitationSent] = useState(null);

    const sendNewInvitation = async () => {

        const emailSent = await reiniviteUser(permissionObject.email, permissionObject.customerId);
        if (emailSent.success) {
            refreshWithUpdate();
            setInvitationSent(true);
        } else {
            setInvitationSent(false);
        }
    }

    const closeModalHandler = (state) => {
        setShowModal(state);
        setInvitationSent(null);
    }

    return <>
        <Button small tertiary onClick={() => closeModalHandler(true)} style={{width:'100%'}}>
            {permissionObject.accountStatus.includes('expired') ? 'Expired' : 'Invited'}
        </Button>
        <Modal show={showModal} close={() => closeModalHandler(false)}>
            <ModalContainer style={{gap: "30px"}}>
                <ModalHeader>Send new invitation?</ModalHeader>

                <ModalText>
                    This action will send a new invitation to the user,<br />
                    user email: {permissionObject.email}
                </ModalText>

                {invitationSent === true && <ModalText style={{color: theme.colors.greenEnergy}}>Invitation sent successfully!</ModalText>}
                {invitationSent === false && <ModalText style={{color: theme.colors.raspberryRed}}>Invitation might have failed to send!</ModalText>}
                {invitationSent === null && <ModalText style={{color:'rgb(0,0,0,0)'}}>placeholder</ModalText>}

                {invitationSent !== true ? 
                    <ButtonRowContainer>
                        <Button primary onClick={sendNewInvitation}>Send invitation</Button>
                        <Button secondary onClick={() => closeModalHandler(false)}>Cancel</Button>
                    </ButtonRowContainer>
                :
                    <ButtonRowContainer>
                        <Button primary onClick={() => closeModalHandler(false)}>Done</Button>
                    </ButtonRowContainer>
                }
            </ModalContainer>
        </Modal>
    </>
}

export default ReinviteUserButton;