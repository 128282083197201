import React, { useState, useEffect } from "react"
import useServerStatus from '../../hooks/useServerStatus';
import useNetwork from "../../hooks/useNetwork";
import TroubleshootInternet from "./TroubleshootInternet";
import TroubleshootHubOffline from "./TroubleshootHubOffline";
import TroubleshootProcessor from "./TroubleshootProcessor";
import TroubleshootServer from "./TroubleshootServer";
import TroubleshootHubError from "./TroubleshootHubError";
import { MAX_OFFLINE_TIME } from "./utils";
import { TroubleshootingContainer } from "./StylesTroubleshooting";
import theme from "../../UI/theme";



const ClientTroubleshooting = (props) => {

    const serverStatus = useServerStatus();
    const networkStatus = useNetwork();
    const [usesEthernet, setUsesEthernet] = useState(true);
    const [localApp, setLocalApp] = useState(false);
    const [notOkHubs, setNotOkHubs] = useState([]);
    const [offlineHubs, setOfflineHubs] = useState([]);
    const [disconnectedProcessors, setDisconnectedProcessors] = useState([]);

    const errorIds = [194, 97, 167, 141, 90, 91, 102, 104, 105, 132, 133, 136, 138, 143, 145, 150, 153, 157, 159, 166, 117];

    useEffect(() => {
        checkLocalApp();
        checkUsesEthernet();
    }, [])

    useEffect(() => {
        checkHubOffline();
        checkNotOkHubs();
    }, [props.hubs]);

    useEffect(() => {
        checkDisconnectedProcessors();
    }, [props.processors]);

    function checkLocalApp() {
        const url = window.location.href;
        if (url.includes('waved') || process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT'){
            setLocalApp(false);
        } else setLocalApp(true)
    } 

    function checkUsesEthernet() {
        const hubs  = props.hubs.filter((hub) => hub.networkConfig?.eth0?.address);
        if (hubs.length > 0) setUsesEthernet(true);
        else setUsesEthernet(false);
    }

    function checkNotOkHubs() {
        const faultyHubs = props.hubs.filter((hub) => hub.hubOK === false);
        if (faultyHubs.length > 0) setNotOkHubs(faultyHubs);
        else setNotOkHubs([]);
    }

    function checkHubOffline () {
        const offline = props.hubs.filter((hub) => hub.secondsSinceLastOnline > MAX_OFFLINE_TIME);
        if (offline) setOfflineHubs(offline);
        else setOfflineHubs([]);
    }

    function checkDisconnectedProcessors() {
        const faultyProcessors = props.processors.filter((processor) => processor.isConnected === 0);
        if (faultyProcessors.length > 0) setDisconnectedProcessors(faultyProcessors);
        else setDisconnectedProcessors([]);
    }

    if ( networkStatus.online === false && localApp === false ) {
        return(
            <TroubleshootInternet
                hubs={props.hubs}
            />
        )
    } else if ( serverStatus === false ) {
        return (
            <TroubleshootServer/>
        )
    } else if ( offlineHubs.length > 0 ) {
        return(
            <TroubleshootHubOffline
                offlineHubs={offlineHubs}
                usesEthernet={usesEthernet}
            />
        )
    } else if ( notOkHubs.length > 0 ) {
        return (
            <TroubleshootHubError
                notOkHubs={notOkHubs}
                usesEthernet={usesEthernet}
            />
        )
    } else if ( disconnectedProcessors.length > 0 ) { 
        return(
            <TroubleshootProcessor
                hubs={props.hubs}
                disconnectedProcessors={disconnectedProcessors}
            />
        )
    }  else if (errorIds.includes(props.customerId)) {
        // remove import from TroubleshootingContainer and theme when done - also dont pass customerId
        // remove errorIds when done
        return (
            <TroubleshootingContainer>
                <h3 style={{color: theme.colors.raspberryRed}}>IMPORTANT!</h3>
                <h4 style={{color: theme.colors.raspberryRed}}>System status:</h4>
                <p>
                    One of our valued partners Liveqube are having trouble with their services at the moment.<br></br>
                    This may affect your music and music volume significantly.
                </p>
                <br></br>
                <p>
                    For more information, please contact Liveqube at: <a style={{color: theme.colors.greenEnergy}} href="mailto:support@liveqube.com">support@liveqube.com</a> <br></br>
                    or call: <a style={{color: theme.colors.greenEnergy}} href="tel:+4724077700">+47 24 07 77 00</a> if urgent.
                </p>
            </TroubleshootingContainer>
        )

    } else {
        return <></>
    }
}

export default ClientTroubleshooting;