import React, { useEffect, useState } from 'react';
import Button from '../UiComponents/Button';
import { SetupStepContainer, SkipButton, StepManagerContainer, SkipButtonContainer, AnimationOverlay, SetupStatusContainer, ConnectionConfirmatoinContainer} from './SetupStepContainers';
import InputField from '../UiComponents/InputField';
import IconLogo from '../UiComponents/IconLogo';
import theme from '../../UI/theme';


const InstallerSetup = ({ hubBluetoothConnection, setSetupStep, SETUP_STEP, ethernet }) => {

    const [registerEmailSent, setRegisterEmailSent] = useState(false);
    const [installerActivationPending, setInstallerActivationPending] = useState(false);
    const [givenInstallerEmail, setGivenInstallerEmail] = useState('');
    const [previousInstallerEmail, setPreviousInstallerEmail] = useState('');
    const [installerEmail, setInstallerEmail] = useState('');
    const [pendingData, setPendingData] = useState(false);
    const [installerNotFound, setInstallerNotFound] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const [showSkip, setShowSkip] = useState(false);
    const dataPendingRef = React.useRef(pendingData);
    dataPendingRef.current = pendingData;

    useEffect(() => {
        syncData('init');
        hubBluetoothConnection.subscribeInstallerData(subscriptionCallback)
    }, []);

    useEffect(() => () => {
        hubBluetoothConnection.unsubscribeInstallerData(subscriptionCallback);
    }, []);

    function subscriptionCallback(event) {
            const value = new TextDecoder().decode(event.target.value);
            var parsedData = hubBluetoothConnection.parse(value);
            syncData();
    }

    function addInstaller() {
        setPendingData(true);
        setInstallerNotFound(false);
        setRegisterEmailSent(false);
        setInstallerActivationPending(false);
        if (givenInstallerEmail) setPreviousInstallerEmail(givenInstallerEmail);
        setInstallerEmail('');
        const installer = {
            email: givenInstallerEmail || previousInstallerEmail,
        };
        hubBluetoothConnection.setInstallerData(installer);
        setTimeout(() => {
            if (dataPendingRef.current) syncData();
        }, 4250);
    }

    async function getInstaller() {
        return await hubBluetoothConnection.getInstallerData();
    }

    async function syncData(init = false) {
        const installer = await getInstaller();
        console.log('Installer: ', installer);
        if (installer.email && installer.found === true && installer.accountStatus === 'active') {
            setInstallerEmail(installer.email);
        } else if (installer.accountStatus === 'invited') {
            setRegisterEmailSent(true);
            setPreviousInstallerEmail(installer.email);
            setInstallerActivationPending(true);
        } else if (installer.found === false){
            if (init) return;
            setInstallerNotFound(true);
        } else setShowSkip(true)
        setPendingData(false);
    }

    function validateEmail(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    function updateGivenEmailHandler(email) {
        setGivenInstallerEmail(email);
        if (validateEmail(email)) setValidEmail(true);
        else setValidEmail(false);
    }

    async function inviteNewUser() {
        console.log('Inviting: ', givenInstallerEmail);
        const installer = { email: givenInstallerEmail };
        hubBluetoothConnection.inviteInstaller(installer);
        setRegisterEmailSent(true);
    }

    function next() {
        setSetupStep(SETUP_STEP.CLIENT);
    }

    function back() {
        if (!ethernet.internet) setSetupStep(SETUP_STEP.WIFI);
        else setSetupStep(SETUP_STEP.ETHERNET);
    }

    function skip() {
        next();
    }

    return (
        <SetupStepContainer>
            <AnimationOverlay/>
            <h2>Installer</h2>
            <p style={{maxWidth: '300px'}}>Email <span style={{fontSize: '14px'}}>(installer account)</span>
                <InputField
                    value={givenInstallerEmail}
                    onChange={(e) => {updateGivenEmailHandler(e.target.value)}}
                    onBlur={(e) => {updateGivenEmailHandler(e.target.value)}}
                    placeholder='email'
                />
            </p>
            <Button disabled={!validEmail} primary small onClick={addInstaller}>{installerNotFound? 'Retry' : 'Add Installer'}</Button>

            {pendingData? 
                <SetupStatusContainer>
                    <p>One moment...</p>
                    <IconLogo preloader/>
                </SetupStatusContainer>
            : null}

            {}

            {installerEmail && !installerNotFound?
                 <SetupStatusContainer>
                    <h4>Installer Found:</h4>
                    <p>{installerEmail}</p>
                    <Button style={{marginTop: '70px'}} primary onClick={next}>Next Step</Button>
                </SetupStatusContainer>
            : null}

            {installerNotFound && !registerEmailSent?
                <SetupStatusContainer>
                    <p style={{textAlign: 'center'}}>Could not find: {previousInstallerEmail}</p>
                    <p>Would you like to register {previousInstallerEmail} as an installer?</p>
                    <Button  primary small onClick={inviteNewUser}>Register</Button>
                </SetupStatusContainer>
            : null}

            {registerEmailSent?
                <SetupStatusContainer>
                    <p>A signup email has been sent to {previousInstallerEmail}</p>
                    <p>Please contine <strong>after</strong> you've completed the signup process</p>
                    {installerActivationPending? <p style={{color: theme.colors.raspberryRed, textAlign: 'center'}}>Please check your email and activate your account...</p> : null}
                    <Button  primary small onClick={addInstaller}>Continue</Button>
                    <Button style={{margin: '50px'}} tertiary small onClick={inviteNewUser}>Resend Email</Button>
                </SetupStatusContainer>
            : null}

            
            <StepManagerContainer>
                <Button disabled={false} tertiary small onClick={back}>Back</Button>
                {/*<Button disabled={!installerEmail} tertiary small onClick={next}>Next</Button>*/}
            </StepManagerContainer>

        </SetupStepContainer>
    );
};

export default InstallerSetup;