import { useEffect } from "react";
import styled from "styled-components";
import useEditableTable from "./hooks/useEditableTable";
import { actionTypes, displayModes } from "../utils/installationTypes";
import EditableList from "./EditableList";
import EditableTable from "./EditableTable";
import EditableTableHeader from "./EditableTableHeader";
import { Placeholder } from "./styles/TableStyles";

const Container = styled.div`
    margin-top: 0px;
`

const EditableTableContainer = ({
    title,
    getColumns,
    parentObjects,
    idField,
    saveObjects,
    deleteObject,
    addObject,
    sortObjects,
    getListItemProps,
    getActions,
    displayMode,
    setDisplayMode,
    getPlaceholder,
    editableTableRef,
    getInfoSection,
    getActionPrompts,
    overrideAddButtonModal,
    showInfoHeader,
    showActionPrompts,
    showNotification,
}) => {
    const [objects, updateObjects, isEdited, saveHandler] = useEditableTable({ parentObjects, idField, saveObjects });
    const columns = getColumns(objects);
    
    useEffect(() => {
        editableTableRef.current = { isEdited, saveHandler };
    }, [isEdited()])

    const hasData = () => {
        return objects && objects.length && objects[0][idField] !== undefined;
    }

    return <Container>
        <EditableTableHeader
            title={title}
            displayMode={displayMode}
            setDisplayMode={setDisplayMode}
            columns={columns}
            addObject={addObject}
            isEdited={isEdited}
            saveHandler={saveHandler}
            actions={getActions()?.filter(action => action.type === actionTypes.LIST)}
            noRecords={objects?.length === 0}
            overrideAddButtonModal={overrideAddButtonModal}
        />

        {getInfoSection && showInfoHeader? <> {getInfoSection()} </> : null}

        {hasData() ? <>
            {displayMode === displayModes.LIST ? <>
                <EditableList
                    columns={columns}
                    objects={objects}
                    getListItemProps={getListItemProps}
                    idField={idField}
                    saveObject={(changedObject) => saveObjects([changedObject])}
                    deleteObject={deleteObject}
                    getActions={getActions}
                    sortObjects={sortObjects}
                    showNotification={showNotification}
                />
            </> : <>
                <EditableTable
                    objects={objects}
                    updateObjects={updateObjects}
                    isEdited={isEdited}
                    columns={columns}
                    idField={idField}
                    deleteObject={deleteObject}
                    sortObjects={sortObjects}
                />
            </>}
        </> : <>
            {objects?.length === 0 && getPlaceholder ? <>
                    {getPlaceholder()}
            </> : <></>}
        </>}
        {getActionPrompts && showActionPrompts? <> {getActionPrompts()} </> : null}
    </Container>
}

export default EditableTableContainer;

