import React from "react";
import Lottie from "react-lottie";

const LottieBaseAnimation = ({height, width, animation}) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
            preserveAspectRatio: true,
        }
    };

    return (
        <Lottie options={defaultOptions} height={height} width={width} />
    );
}

export default LottieBaseAnimation;